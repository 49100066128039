import React from "react"
import Layout from "../components/layout"
import Header from "../components/section/header"
import Seo from "../components/seo"

export default function mediumship({ pageContext: { data }, location }) {
  const frontmatter = data?.frontmatter
  return (
    <Layout>
      <Seo title={frontmatter?.title} pathname={location.pathname} />
      <Header title={frontmatter?.title} />
      <Content content={data?.html} />
    </Layout>
  )
}

const Content = ({ content }) => {
  return (
    <section className="max-w-5xl w-11/12 2xl:w-full mx-auto py-6">
      <div className="flex flex-col gap-6">
        {/* <h1 className="text-3xl font-serif">How Mediumship Helps Grief</h1> */}

        <div
          className="prose text-black xl:prose-lg prose-ul:list-disc mx-auto"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </section>
  )
}
